/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// You can delete this file if you're not using it
import './src/styles/variables/colors.scss'
import './src/styles/global.scss'
import './src/styles/layout.scss'
import './src/styles/typography.scss'
import './src/styles/shadow.scss'
